/**
 * Future Worlds Theme JavaScript
 *
 * This file initializes all the JavaScript functionality for the Future Worlds theme.
 * GSAP is already loaded via WordPress enqueue.
 */

document.addEventListener('DOMContentLoaded', () => {
    // Initialize all modules
    initSuccessStoriesSlider();
    initLinkedinSlider();
    initResponsiveNavigation();
    initBenefitsAccordion();
    initMailchimpValidation();
    initGsapAnimations();

    console.log('Future Worlds Theme JS initialized!');
});

/**
 * Success Stories Slider
 * Initializes the slider for success stories with image synchronization
 */
function initSuccessStoriesSlider() {
    const successStoriesSlider = document.querySelector('.js-stories-slider');
    if (!successStoriesSlider) return;

    const splide = new Splide('.js-stories-slider', {
        type: 'fade',
        perPage: 1,
        rewind: true,
        pagination: false,
        arrows: false,
        speed: 1400
    });

    const updateStoryImage = (slide) => {
        const storyImage = document.querySelector('.js-story-image img');
        if (!storyImage) return;

        const imageUrl = slide.dataset.imageUrl;
        const imageAlt = slide.dataset.imageAlt || '';

        if (imageUrl) {
            storyImage.src = imageUrl;
            storyImage.alt = imageAlt;
        }
    };

    // Handle events
    splide.on('mounted', () => {
        const activeSlide = splide.Components.Elements.slides[0];
        updateStoryImage(activeSlide);
    });

    splide.on('moved', () => {
        const activeSlide = splide.Components.Elements.slides[splide.index];
        updateStoryImage(activeSlide);
    });

    splide.mount();

    // Navigation buttons
    const prevButton = document.querySelector('.js-prev');
    const nextButton = document.querySelector('.js-next');

    if (prevButton) {
        prevButton.addEventListener('click', () => splide.go('<'));
    }

    if (nextButton) {
        nextButton.addEventListener('click', () => splide.go('>'));
    }
}

/**
 * LinkedIn Slider
 * Initializes the slider for LinkedIn posts
 */
function initLinkedinSlider() {
    const linkedinSlider = document.querySelector('.js-linkedin-slider');
    if (!linkedinSlider) return;

    const linkedInSplide = new Splide('.js-linkedin-slider', {
        type: 'loop',
        perPage: 3,
        gap: '1rem',
        pagination: false,
        arrows: false,
        speed: 1400,
        drag: false,
        breakpoints: {
            767: {
                perPage: 1,
                drag: true,
                type: 'rewind',
            },
            1024: {
                perPage: 2,
                drag: true,
                type: 'rewind',
            },
        }
    });

    linkedInSplide.mount();

    // Navigation buttons
    const prevButton = document.querySelector('.js-linkedin-prev');
    const nextButton = document.querySelector('.js-linkedin-next');

    if (prevButton) {
        prevButton.addEventListener('click', () => linkedInSplide.go('<'));
    }

    if (nextButton) {
        nextButton.addEventListener('click', () => linkedInSplide.go('>'));
    }
}

/**
 * Responsive Navigation
 * Handles the mobile menu toggle
 */
function initResponsiveNavigation() {
    const navTrigger = document.querySelector('.js-responsive-navigation-trigger');
    const responsiveNav = document.querySelector('.js-responsive-navigation');

    if (navTrigger && responsiveNav) {
        navTrigger.addEventListener('click', () => {
            responsiveNav.classList.toggle('hidden');
            navTrigger.classList.toggle('is-open');
        });
    }
}

/**
 * Benefits Accordion
 * Manages the accordion behavior for benefits section
 */
function initBenefitsAccordion() {
    const benefitDetails = document.querySelectorAll('.js-benefit');

    benefitDetails.forEach(detail => {
        detail.addEventListener('click', (e) => {
            // If this element is already open, don't close it when clicking inside it
            if (e.target.closest('.js-benefit-summary') || !detail.open) {
                // Close all other details elements
                benefitDetails.forEach(otherDetail => {
                    if (otherDetail !== detail) {
                        otherDetail.open = false;
                    }
                });
            }
        });
    });
}

/**
 * Mailchimp Form Validation
 * Validates the newsletter signup form
 */
function initMailchimpValidation() {
    const mailchimpForm = document.querySelector('.js-mailchimp-form');
    if (!mailchimpForm) return;

    const nameField = mailchimpForm.querySelector('.js-mailchimp-name');
    const emailField = mailchimpForm.querySelector('.js-mailchimp-email');
    const relationshipField = mailchimpForm.querySelector('.js-mailchimp-relationship');
    const privacyCheckbox = mailchimpForm.querySelector('.js-mailchimp-privacy');

    mailchimpForm.addEventListener('submit', (e) => {
        let isValid = true;

        // Reset validation state
        [nameField, emailField, relationshipField, privacyCheckbox].forEach(field => {
            if (field) field.classList.remove('is-invalid');
        });

        // Reset privacy overlay
        const privacyOverlay = mailchimpForm.querySelector('.js-mailchimp-privacy-overlay');
        if (privacyOverlay) {
            privacyOverlay.classList.remove('opacity-100');
        }

        // Validate name field
        if (nameField && !nameField.value.trim()) {
            nameField.classList.add('is-invalid');
            isValid = false;
        }

        // Validate email field
        if (emailField) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailField.value.trim() || !emailPattern.test(emailField.value)) {
                emailField.classList.add('is-invalid');
                isValid = false;
            }
        }

        // Validate relationship field
        if (relationshipField && relationshipField.value === "") {
            relationshipField.classList.add('is-invalid');
            isValid = false;
        }

        // Validate privacy checkbox
        if (privacyCheckbox && !privacyCheckbox.checked) {
            privacyCheckbox.classList.add('is-invalid');
            if (privacyOverlay) {
                privacyOverlay.classList.add('opacity-100');
            }
            isValid = false;
        }

        if (!isValid) {
            e.preventDefault();
        }
    });
}

/**
 * GSAP Animations
 * Word-by-word animations for titles using SplitText
 */
function initGsapAnimations() {
    gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray('.js-gsap-title').forEach(title => {
        gsap.set(title, { autoAlpha: 1 });

        if (title.splitText) {
            title.splitText.revert();
        }

        const splitText = new SplitText(title, {
            type: "words",
        });

        gsap.set(splitText.words, { autoAlpha: 0, y: 0 });

        gsap.to(splitText.words, {
            autoAlpha: 1,
            y: 0,
            duration: 1.5,
            stagger: 0.05,
            ease: "power2.out",
            scrollTrigger: {
                trigger: title,
                start: 'top 85%',
            }
        });
    });

    gsap.utils.toArray('.js-gsap-fade-container').forEach(container => {
        const elements = container.querySelectorAll('.js-gsap-fade');
        gsap.set(elements, { autoAlpha: 0, y: 20 });

        gsap.timeline({
            scrollTrigger: {
                trigger: container,
                start: "top bottom-=50",
            }
        })
        .to(elements, {
            autoAlpha: 1, y: 0,
            duration: 1.5,
            ease: "power2.out",
            stagger: 0.25
        });
    });

    gsap.utils.toArray('.js-gsap-fade').forEach(element => {
        if (!element.closest('.js-gsap-fade-container')) {
            gsap.set(element, { autoAlpha: 0, y: 20 });

            gsap.to(element, {
                autoAlpha: 1, y: 0,
                duration: 0.6,
                ease: "power2.out",
                scrollTrigger: {
                    trigger: element,
                    start: "top bottom-=50"
                }
            });
        }
    });



    gsap.utils.toArray('.js-gsap-countup').forEach(element => {
        const endValue = parseInt(element.textContent, 10);

        gsap.set(element, { textContent: 0 });

        gsap.to(element, {
            textContent: endValue,
            duration: 2,
            ease: "power2.inOut",
            roundProps: "textContent",
            delay: 0.5,
            scrollTrigger: {
                trigger: element,
                start: 'top bottom',
            },
            onUpdate: function() {
                element.textContent = Math.round(element.textContent);
            }
        });
    });
    ScrollTrigger.refresh();

 }